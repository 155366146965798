<!--司机余额-->
<template>
    <div>
        <el-form :inline="true" class="form" label-position="right">
            <el-form-item label="司机姓名:">
                <el-input v-model="search.name" placeholder="请输入司机姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
                <el-input v-model="search.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="司机商户号:">
                <el-input v-model="search.huifuId" placeholder="请输入司机商户号"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="headSearch">查询</el-button>
                <el-button type="info" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>
        <table-compnent :records="tableData" :loading="loading" @searchHandler="doSearch">
            <template v-slot:content>
                <el-table-column label="司机姓名">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toDriverDetail(scope.row.no)">{{ scope.row.name }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="huifuId" label="商户号"></el-table-column>
                <el-table-column prop="mobile" label="手机号"></el-table-column>
                <el-table-column prop="idCard" label="身份证号"></el-table-column>
                <el-table-column prop="carNumber" label="车牌号"></el-table-column>
                <el-table-column prop="balanceAmt" label="账户余额(元)"></el-table-column>
                <el-table-column label="可用余额(元)">
                    <template slot-scope="scope">
                        <span style="color:#FA8C16">{{ scope.row.avlBal }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toDetail(scope.row.no)">余额明细
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </table-compnent>
    </div>
</template>
  
  <script>
import basePage from "../../base/basePage.vue";
import TableCompnent from "@/components/table/baseTablesComponents";
import TripFinancialApi from "@/api/TripFinancialApi";
export default {
    extends: basePage,
    components: {
        TableCompnent
    },
    data() {
        return {
            tableData: {},
            search: {
                name: "",
                huifuId: "",
            },
            TimeArr: [],
            page: {},
            loading: true,
        };
    },
    methods: {
        reset() {
            this.search = this.$options.data().search;
            this.TimeArr = [];
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        headSearch() {
            this.doSearch({ pageSize: 20, pageNum: 1 });
        },
        doSearch(params) {
            this.loading = true;
            this.page = params;
            this.search.pageSize = this.page.pageSize;
            this.search.pageNum = this.page.pageNum;
            TripFinancialApi.findDriverAccountBalanceList(this.search).then((resp) => {
                if (resp.code === '200') {
                    console.log(resp);
                    this.tableData = resp.data;
                    this.loading = false;
                }
            });
        },
        toDetail(no) {
            this.navTo(`/driverBalanceDetails/${no}`)
        },
        toDriverDetail(no) {
            this.navTo(`/TripDriverDetail/${no}`)
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input,
.el-select,
.el-date-editor {
    width: 280px;
}

::v-deep .el-form-item__label {
    white-space: nowrap;
    display: inline-block;
    color: #666666;
}

.el-form-item {
    display: flex;
    justify-content: flex-end;
}

.form {
    display: grid;
    grid-template-columns: 370px auto auto 180px;
    grid-template-rows: auto;
}
</style>